import React, { useContext, useState, useEffect } from 'react'
import { Typography } from 'foundations-library/components'
import { ThemeContext } from 'providers'
import './strype.css'
import { makeStyles } from '@material-ui/core'

const style = () => ({
    stripeFields: {
        display: 'flex',
        '& .stripe-field-base:not(:first-child)': {
            marginLeft: '20px'
        }
    }
})
const useStyles = makeStyles(style)

interface PropsInterface {
    pMethod: any;
    isValid(v): any;
    disabled: boolean;
    resetInputs: boolean;
    inputsReset(): any;
    elements: any;
    setElements(v): any;
}

/**
 *
 */
const StripeFields = ({
    pMethod,
    isValid,
    disabled = false,
    resetInputs = false,
    inputsReset,
    elements,
    setElements
}: PropsInterface): JSX.Element => {
    const [formIsValid, setFormIsValid] = useState(false)
    const styles = useStyles()
    const themeContext = useContext(ThemeContext)

    useEffect(() => isValid(formIsValid), [formIsValid])

    useEffect(() => {
        for (const key of Object.keys(elements)) {
            if (elements[key].el) elements[key].el.clear()
        }
        inputsReset()
    }, [resetInputs])

    useEffect(() => {
        for (const key of Object.keys(elements)) {
            if (elements[key].el) elements[key].el.update({ disabled })
        }
    }, [disabled])

    const handleStripeError = (err, field) => {
        const { error } = err
        pMethod.alert = null
        elements[field].error = error ? error.message : ''
        elements[field].is_complete = err.complete
        setElements({ ...elements })
        setFormIsValid(Object.values(elements).every((x) => !x.error && x.is_complete))
    }

    useEffect(() => {
        const { colors } = themeContext?.['foundations-library'] ?? {}
        pMethod.load_stripe().then(() => {
            if (pMethod.alert) return false
            const appearance = {
                theme: 'none'
            }
            const elementStyles = {
                // https://docs.stripe.com/js/appendix/style
                base: {
                    '::placeholder': {
                        color: colors.neutral[700],
                        fontWeight: 400
                    }
                },
                invalid: {}
            }

            const elementClasses = {
                base: 'stripe-field-base',
                focus: 'stripe-field-focus',
                invalid: 'stripe-field-invalid'
            }
            const sElements = pMethod.stripe.elements({
                appearance,
                fonts: [{
                    family: 'Montserrat',
                    cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:500',
                    weight: 500
                }]
            })
            for (const key of Object.keys(elements)) {
                let conf = {
                    style: elementStyles,
                    classes: elementClasses
                }

                if (elements[key].conf && typeof elements[key].conf === 'object') {
                    conf = {
                        ...conf,
                        ...elements[key].conf
                    }
                }
                elements[key].el = sElements.create(elements[key].stripe_el, conf)
            }
            for (const key of Object.keys(elements)) {
                elements[key].el.mount(elements[key].id)
            }
            for (const key of Object.keys(elements)) {
                elements[key].el.on('change', async (e) => {
                    handleStripeError(e, key)
                })
            }
            setElements({ ...elements })
            return true
        })
    }, [])

    return <div className={styles.stripeFields} id="stripe-create-fields">
        <div id="stripe-card-number" className="stripe-field fs-exclude"></div>
        {elements.number.error && <Typography variant='helperText' color='destructive'>{ elements?.number?.error }</Typography>}
        <div className="exp-wrapper">
            <div id="stripe-card-exp" className="stripe-field fs-exclude"></div>
            {elements.exp.error && <Typography variant='helperText' color='destructive'>{ elements?.exp?.error }</Typography>}
        </div>
        <div className="cvc-wrapper">
            <div id="stripe-card-cvc" className="stripe-field fs-exclude"></div>
            {elements.cvc.error && <Typography variant='helperText' color='destructive'>{ elements?.cvc?.error }</Typography>}
        </div>
        <div className="error" role="alert">
            <span className="message"></span>
        </div>
    </div>
}

export default StripeFields
