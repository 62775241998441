export default /**
eeeeeeeeeeeeeee *
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee */
() => ({
    loader: {
        width: '100%',
        height: '100%',
        textAlign: 'center'
    },
    message: {
        marginTop: '5px'
    }
})
