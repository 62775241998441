import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Icon from '../Icon/Icon'
import Typography from '../Typography/Typography'
import jss from './style'

const useStyles = makeStyles(jss)

interface PropsInterface {
    message?: string
    'data-testid'?: string
    color: string
}

/**
 *
 */
const Loader = ({
    message,
    children,
    'data-testid': dataTestId = 'loader',
    ...props
}: PropsWithChildren<PropsInterface>): JSX.Element => {
    const styles = useStyles()
    const loaderRef = useRef(null)
    const [top, setTop] = useState(0)
    useEffect(() => {
        console.log(window.innerHeight)
        if (loaderRef?.current) {
            setTop(
                (window.innerHeight - loaderRef.current.offsetTop) / 2 - 105
            )
        }
    }, [])
    // todo modal behavior
    // todo onclick
    return <div className={styles.loader} ref={loaderRef} data-testid={dataTestId}>
        <Icon name='spinner' spin style={{ marginTop: top }} {...props} data-testid={`${dataTestId}-icon`}/>
        {
            (children || message) && <Typography variant='body2' className={styles.message} data-testid={`${dataTestId}-message`}>
                {children || message}
            </Typography>
        }
    </div>
}

export default Loader
